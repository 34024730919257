import './Responsive.css'

const Responsive = () =>{
    return(
        <div className="responsive__menu">
            <a className='header__menu-item' href='#about'>Quem Somos</a>
            <a className='header__menu-item' href='#service'>Serviços</a>
            <a className='header__menu-item' href='#testimonial'>Depoimentos</a>
            <a className='header__menu-item' href='#customer'>Clientes</a>
            <a className='header__menu-item cta' href='https://api.whatsapp.com/send?phone=5561981756165' target='_blank' rel='noreferrer'>Fale com a Plano</a>
        </div>
    )
}

export default Responsive