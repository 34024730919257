import { Swiper, SwiperSlide } from 'swiper/react'
import './Slide.css'
import 'swiper/css'
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const testimonials = [
    {
        "id": 1,
        "imagem": "../img/testimonial/geordano.png",
        "nome": "Giordano Martins",
        "empresa": "Supermercado Parati",
        "depoimento": "Sem dúvida, o trabalho de consultoria do senhor Luiz Felipe Escarlate proporcionou ao Supermercado Parati e aos seus membros uma postura técnica e profissional. Crescimento e excelência foram marcas indiscutíveis da consultoria!"
    },
    {
        "id": 2,
        "imagem": "../img/testimonial/isabela.jpeg",
        "nome": "Isabella Resende",
        "empresa": "Frozen Fit",
        "depoimento": "Fiz a consultoria de Formação de Preço de Venda com o Luiz Felipe e foi incrível! Hoje tenho outra visão quando penso em precificação e principalmente em como aumentar minhas margens."
    },
    {
        "id": 3,
        "imagem": "../img/testimonial/magda.jpg",
        "nome": "Magda Jane",
        "empresa": "Papoula Boutique",
        "depoimento": "A minha experiência com a consultoria do Luiz Felipe foi que trabalhando com o simples alcançamos o extraordinário. Nunca os números foram tão fáceis pra mim!"
    },
    {
        "id": 4,
        "imagem": "../img/testimonial/leticia.jpg",
        "nome": "Letícia Castelo Branco Figueiredo",
        "empresa": "Criapet",
        "depoimento": "A parceria com o Escarlate resultou em um crescimento significativo em um curto intervalo de tempo, tanto do ponto de vista financeiro quanto gerencial. Ele nos acompanha com dedicação de um amigo e cobra com o zelo de um pai."
    },
    {
        "id": 5,
        "imagem": "../img/testimonial/leticia2.jpg",
        "nome": "Letícia Estigarraga",
        "empresa": "Agência MeiaUm Marketing Digital Ltda",
        "depoimento": "A Consultoria que o Sr Luiz Felipe prestou a MeiaUm Digital foi de suma importância. Através dessa parceria conseguimos organizar, estruturar novos projetos e analisar melhor nossos números e projeções de forma simples e bem didática."
    },
    {
        "id": 6,
        "imagem": "../img/testimonial/alexandre.jpeg",
        "nome": "Alexandre Palombo",
        "empresa": "Policlínica Mais",
        "depoimento": "Contratar a consultoria do Luiz Felipe foi uma das decisões mais acertadas que tive para o meu negócio, pois o mesmo me ajudou a entender mais sobre a parte de processos e na implantação de uma estratégia mais voltada para a venda ativa."
    },
    {
        "id": 7,
        "imagem": "../img/testimonial/lilian.jpeg",
        "nome": "Dra Lilian Carvalho",
        "empresa": "Odonto Sublime",
        "depoimento": "Iniciei a consultoria com o consultório vazio, voltando a atender após licença maternidade e no meio de uma pandemia. Em poucas semanas a agenda já estava lotada e o consultório voltando a ter caixa para investir em novos equipamentos e cursos."
    },
    {
        "id": 8,
        "imagem": "../img/testimonial/luisa.jpeg",
        "nome": "Luísa Amorim",
        "empresa": "Ana Achaval Studio",
        "depoimento": "O trabalho do Felipe foi impecável. Teve paciência para escutar e entender meu negócio, minhas dificuldades e desafios e me ajudar na missão de precificar de uma forma mais assertiva! Recomendo demais!"
    },
    {
        "id": 9,
        "imagem": "../img/testimonial/gabriel.jpeg",
        "nome": "Gabriel Marra Menegaz",
        "empresa": "Kidmais Festas",
        "depoimento": "Muito obrigado pela consultoria, Luiz Felipe. O meu negócio fica em outro nível quando eu sei quanto vale o meu serviço."
    },
    {
        "id": 10,
        "imagem": "../img/testimonial/nathalia.jpg",
        "nome": "Nathália Lira",
        "empresa": "Movimento Animal",
        "depoimento": "Os anos de consultoria com o Luiz trouxeram sobriedade às escolhas e tornaram muitos sonhos em projetos realizados. Além de me capacitar para a gestão de riscos, trazendo à tona a realidade do cenário para que não fosse me pegar de surpresa."
    },
    {
        "id": 11,
        "imagem": "../img/testimonial/enio.jpeg",
        "nome": "Dr. Enio e Eva de Souza",
        "empresa": "Clinica Sorriclean",
        "depoimento": "A consultoria feita com o Luiz Felipe em nossa empresa foi um verdadeiro divisor de águas em nossa vida financeira e profissional, aprendemos a administrar de uma maneira inteligente e saudável. Somos eternamente gratos pelo aprendizado."
    },
    {
        "id": 12,
        "imagem": "../img/testimonial/claudio.jpeg",
        "nome": "Claudio Tavares",
        "empresa": "Pepita Pães e Sabores",
        "depoimento": "A Consultoria com o Luiz Felipe Escarlate me fez rever uma série de conceitos. Hoje tenho metas de vendas para cada item do meu cardápio e assim garanto não só o pagamento de todas minhas despesas fixas, mas garanto também o lucro no bolso."
    }
]
const Slide = () =>{
    return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="testimonialSwiper"
      >
        {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
                <div className='slide__container'>
                    <img className='slide__avatar' src={testimonial.imagem} alt={testimonial.nome}/>
                    <h3 className='slide__name'>{testimonial.nome}</h3>
                    <h5 className='slide__position'>{testimonial.empresa}</h5>
                    <p className='slide__testimonial'>{testimonial.depoimento}</p>
                </div>
            </SwiperSlide>
        ))}
      </Swiper>
    </>
    )
}

export default Slide