import Facts from '../Facts'
import './About.css'

const About = () => {
    return(
        <section id="about" className="about">
            <div className="container about__container">
                <div className="about__col-left">
                    <div className="about__title-text">
                        <h4 className="about__subtitle">Quem Somos</h4>
                        <h2 className="about__title">Sobre a Plano</h2>
                    </div>
                    <div className="about__content-text">
                        <p className="about__paragraph">A Plano Consultoria é uma empresa de consultoria e assessoria para micro e pequenos empresários.</p>
                        <p className="about__paragraph">A Plano Consultoria nasceu em Brasília em 1999 a partir das experiências empreendedoras do seu sócio fundador Luiz Felipe Escarlate.</p>
                        <p className="about__paragraph">Orientamos decisões estratégicas e desenvolvemos soluções relacionadas ao dia a dia das empresas.</p>
                    </div>
                    <hr className="about__divider" />
                    <div className="about__founder"> 
                            <img className="about__founder-avatar" src="../img/fundador.png" alt="Luiz Felipe Escarlate" />
                            <p className="about__founder-quote">“A formação acadêmica me ensinou muito, mas o que eu aprendi tocando negócios não poderia ficar só comigo. Eu preciso contar isso para todo mundo.“</p>
                    </div>
                </div>
                <div className="about__col-left">
                    <img src="../img/about.webp" alt="Pessoas conversando em volta da mesa." />
                </div>
            </div>
            <div className="container">
                <div className="about__fun-facts">
                    <Facts 
                        icon="../img/clientes.svg" 
                        txtalticon="Ícone clientes" 
                        number="2000"
                        feature="Clientes atendidos"
                        sulfix=""
                    />
                    <Facts 
                        icon="../img/projetos.svg" 
                        txtalticon="Ícone projetos" 
                        number="1000"
                        feature="Projetos realizados"
                        sulfix=""
                    />
                    <Facts 
                        icon="../img/satisfacao.svg" 
                        txtalticon="Ícone satisfação" 
                        number="98"
                        feature="Grau de satisfação"
                        sulfix="%"
                    />
                </div>
            </div>
        </section>
    )
}

export default About