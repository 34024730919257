import React, { useState } from 'react';
import Responsive from '../Responsive'
import './Header.css'

const Header = () =>{
    const [isResponsiveVisible, setResponsiveVisible] = useState(false);

    const toggleResponsive = () => {
        setResponsiveVisible(!isResponsiveVisible);
    };
    return(
        <header id='header' className='header'>
            <div className='container header__container header_desktop'>
                <div className='header__logo'>
                    <img src='../img/logo.png' alt="Logo Plano Consultoria Empresarial" />
                </div>
                <div className='header__menu'>
                    <a className='header__menu-item' href='#about'>Quem Somos</a>
                    <a className='header__menu-item' href='#service'>Serviços</a>
                    <a className='header__menu-item' href='#testimonial'>Depoimentos</a>
                    <a className='header__menu-item' href='#customer'>Clientes</a>
                    <a className='header__menu-item cta' href='https://api.whatsapp.com/send?phone=5561981756165' target='_blank' rel='noreferrer'>Fale com a Plano</a>
                </div>
            </div>
            <div className='container header__container header_mobile'>
                <div className='header_mobile_wrapper'>
                    <div className='header__logo'>
                        <img src='../img/logo.png' alt="Logo Plano Consultoria Empresarial" />
                    </div>
                    <div className='header_mobile_menu-icon' onClick={toggleResponsive}>
                        <img src="../img/menu.svg" alt="Icone menu"/>
                    </div>
                </div>
                {isResponsiveVisible && <Responsive />}
            </div>
        </header>
    )
}

export default Header