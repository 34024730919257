import { useState } from 'react'
import './Card.css'
import Button from '../Button'

const Card = (props) => {
    const [hovered, setHovered] = useState(false)

    const articleBackground = {
        backgroundImage: `url(${props.image})`,
      }
    return (
        <article 
            className="card" 
            style={articleBackground}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className='card'>
                {!hovered && <h4 className="card__title">{props.title}</h4>}
                {hovered && 
                    <div className='card__hovered'>
                        <h4 className="card__title--hovered">{props.title}</h4>
                        <p className="card__description">{props.text}</p>
                        <div className="card__button--hovered">
                            <Button link="https://api.whatsapp.com/send?phone=5561981756165">
                                FALE COM A PLANO
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </article>
    )
}

export default Card