import Button from '../Button'
import './Banner.css'

const Banner = () => {
    return(
        <section id="banner" className="banner">
            <div className="container banner__container">
                <div className="banner__wrapper">
                    <div className="banner__box">
                        <h1 className="banner__title">Consultoria</h1>
                        <h2 className="banner__sutitle">Para o seu negócio</h2>
                        <p className="banner__description">Impulsione o crescimento do seu negócio com estratégias personalizadas.</p>
                        <div>
                            <Button link="https://api.whatsapp.com/send?phone=5561981756165">
                                FALE COM A PLANO
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner