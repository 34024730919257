import './Footer.css'

const Footer = () => {
    return(
        <section id="footer" class="footer">
            <p className='footer__text'>Plano COnsultoria Empresarial - 2024 | Todos os direitos reservados</p>
        </section>
    )
}

export default Footer