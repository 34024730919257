import './Facts.css'
import CountUp from 'react-countup';

const Facts = (props) => {
    return(
        <section id="facts" className="facts">
            <div className="facts_container">
                <div className="facts__item">
                    <img className="facts__item-image" src={props.icon} alt={props.txtalticon} />
                    <h3 className="facts__item-number">
                        <CountUp 
                            end={props.number} 
                            duration={3.5} 
                            prefix="+" 
                            suffix={props.sulfix}
                            separator= ""
                        />
                    </h3>
                    <p className="facts__item-feature">{props.feature}</p>
                </div>
            </div>
        </section>
    )
}

export default Facts