import Header from "./components/Header";
import Banner from "./components/Banner";
import About from "./components/About";
import Service from "./components/Service";
import Testimonial from "./components/Testimonial";
import Customer from "./components/Customer";
import Cta from "./components/Cta";
import Footer from "./components/Footer";
import Whatsapp from "./components/Whatsapp";


function App() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      <Service />
      <Testimonial />
      <Customer />
      <Cta />
      <Footer />
      <Whatsapp />
    </>
  );
}

export default App;
