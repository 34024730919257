import './Customer.css'

const Customer = () =>{
    return(
        <section id="customer" className='customer'>
            <div className='container customer__container'>
                <div className="customer__header">
                    <h3 className="customer__subtitle">Clientes</h3>
                    <h2 className="customer__title">O que adquirimos ao longo da caminhada</h2>
                </div>
                <div className='customer__logos'>
                    <img src="../img/customer/01.png" alt='' />
                    <img src="../img/customer/02.png" alt='' />
                    <img src="../img/customer/03.png" alt='' />
                    <img src="../img/customer/04.png" alt='' />
                    <img src="../img/customer/05.png" alt='' />
                    <img src="../img/customer/06.png" alt='' />
                    <img src="../img/customer/07.png" alt='' />
                    <img src="../img/customer/08.png" alt='' />
                    <img src="../img/customer/09.png" alt='' />
                    <img src="../img/customer/10.png" alt='' />
                    <img src="../img/customer/11.png" alt='' />
                    <img src="../img/customer/12.png" alt='' />
                    <img src="../img/customer/13.png" alt='' />
                    <img src="../img/customer/14.png" alt='' />
                    <img src="../img/customer/15.png" alt='' />
                    <img src="../img/customer/16.png" alt='' />
                    <img src="../img/customer/17.png" alt='' />
                    <img src="../img/customer/18.png" alt='' />
                    <img src="../img/customer/19.png" alt='' />
                    <img src="../img/customer/20.png" alt='' />
                    <img src="../img/customer/21.png" alt='' />
                    <img src="../img/customer/22.png" alt='' />
                    <img src="../img/customer/23.png" alt='' />
                    <img src="../img/customer/24.png" alt='' />
                    <img src="../img/customer/23.png" alt='' />
                    <img src="../img/customer/24.png" alt='' />
                    <img src="../img/customer/25.png" alt='' />
                    <img src="../img/customer/26.png" alt='' />
                    <img src="../img/customer/27.png" alt='' />
                    <img src="../img/customer/28.png" alt='' />
                    <img src="../img/customer/29.png" alt='' />
                    <img src="../img/customer/30.png" alt='' />
                    <img src="../img/customer/31.png" alt='' />
                    <img src="../img/customer/32.png" alt='' />
                    <img src="../img/customer/33.png" alt='' />
                    <img src="../img/customer/34.png" alt='' />
                    <img src="../img/customer/35.png" alt='' />
                    <img src="../img/customer/36.png" alt='' />
                    <img src="../img/customer/37.png" alt='' />
                    <img src="../img/customer/38.png" alt='' />
                    <img src="../img/customer/39.png" alt='' />
                    <img src="../img/customer/40.png" alt='' />
                    <img src="../img/customer/41.png" alt='' />
                    <img src="../img/customer/42.png" alt='' />
                    <img src="../img/customer/43.png" alt='' />
                </div>
            </div>
        </section>
    )
}

export default Customer