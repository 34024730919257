
import Button from '../Button'
import './Cta.css'

const Cta = () => {
    return (
        <section id="cta" className="cta" style={{ backgroundImage: 'url("../img/cta.png")' }}>
            <div className="container cta__container">
                <h3 className="cta__subtitle">Descubra o que podemos fazer pelo seu negócio</h3>
                <h2 className="cta__title">A Plano tem a solução certa para seu negócio!</h2>
                <div className="cta__button">
                    <Button link="https://api.whatsapp.com/send?phone=5561981756165">
                        Fale com a plano
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default Cta