import Slide from '../Slide'
import './Testimonial.css'

const Testimonial = () =>{
    return (
        <section id="testimonial" className="testimonial">
            <div className="container testimonial__container">
                <div className="testimonial__header">
                    <h3 className="testimonial__subtitle">Depoimentos</h3>
                    <h2 className="testimonial__title">o que os clientes estão falando</h2>
                </div>
                <div>
                    <Slide />
                </div>
                <hr className='testimonial__divisor' />
            </div>
        </section>
    )
}

export default Testimonial