import './Whatsapp.css'

const Whatsapp = () => {
    return(
        <div class="floating_btn">
            <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5561981756165">
            <div class="contact_icon">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.238 6.72233C19.3177 4.80083 16.7638 3.7415 14.0432 3.74033C8.435 3.74033 3.87217 8.30083 3.871 13.9067C3.86867 15.6905 4.3365 17.444 5.22783 18.9898L3.78467 24.2585L9.177 22.8445C10.6692 23.6565 12.3398 24.0823 14.0385 24.0823H14.0432C19.649 24.0823 24.2118 19.5207 24.2142 13.9148C24.2153 11.1988 23.1583 8.645 21.238 6.72233Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.2775 15.82L15.7512 15.3498C16.1863 14.9182 16.8747 14.8633 17.3763 15.2157C17.8617 15.5563 18.3003 15.862 18.7087 16.1467C19.3573 16.597 19.4355 17.521 18.8767 18.0787L18.4578 18.4975" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.50247 9.54216L9.92131 9.12333C10.479 8.56566 11.403 8.64383 11.8533 9.29133C12.1368 9.69966 12.4425 10.1383 12.7843 10.6237C13.1366 11.1253 13.083 11.8137 12.6501 12.2488L12.18 12.7225" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.4578 18.4975C16.73 20.2172 13.8262 18.7565 11.5337 16.4628" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.536 16.4663C9.24349 14.1727 7.78282 11.27 9.50249 9.54216" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.18 12.7225C12.5522 13.3093 13.0293 13.8903 13.5683 14.4293L13.5707 14.4317C14.1097 14.9707 14.6907 15.4478 15.2775 15.82" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            </a>
        </div>
    )
}

export default Whatsapp