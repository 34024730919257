import Card from '../Card'
import './Service.css'

const Service = () =>{
    return(
        <section id="service" className="service">
            <div className="container service__container">
                <div className="service__header">
                    <h3 className="service__subtitle">Serviços</h3>
                    <h2 className="service__title">o que fazemos</h2>
                </div>
                <div className="service__card">
                    <Card 
                        image="../img/consultoria-estrategica.webp"
                        title="Consultoria Estratégica"
                        text="Apoiamos empresários na ampliação do seu horizonte de percepção sobre o seu negócio. Um olhar mais amplo favorece a identificação de oportunidades e, consequentemente, dos caminhos a serem percorridos."
                    />
                    <Card 
                        image="../img/consultoria-financeira.webp"
                        title="Consultoria Financeira"
                        text="Métodos de planejamento e de gestão financeira práticos e acessíveis a qualquer tipo de pessoa com foco em resultados. Para muitos as finanças são como um bicho de sete cabeças. Para nós é algo simples e intuitivo que permite ao empresário medir o alcance dos seus objetivos com um estalar de dedos."
                    />
                    <Card 
                        image="../img/consultoria-processos.webp"
                        title="Consultoria em processos e rotinas de trabalho"
                        text="Em um trabalho a quatro mãos, empresário e consultor, desenvolvem métodos de funcionamento simples e objetivos. Sabemos que o micro empresário atua sozinho e, portanto, tudo que acontece na empresa precisa ser prático e sem rodeios, porém eficiente."
                    />
                    <Card 
                        image="../img/solucoes-educacionais.webp"
                        title="Soluções Educacionais"
                        text="Sempre tendo como tema o dia a dia do empreendedor, nossas soluções educacionais preparam empresários e os seus colaboradores para o que efetivamente precisa ser feito."
                    />
                </div>
            </div>
        </section>
    )
}

export default Service